<template>
  <v-card
    class="mx-auto my-12"
    
  >
    <v-img
      height="150"
      :src="pic"
      class="pa-5"
      aspect-ratio="1.7"
    />
    <v-card-title>{{ name }}</v-card-title>

    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="me-1">{{ title }}</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        {{ desc }}
      </v-expansion-panel-content>
      <v-expansion-panel-footer>
        <v-btn
        color="primary"
        text
        @click="handleAction(action)"
      >
        {{action.label || 'cliquer ici'}}
      </v-btn>
      </v-expansion-panel-footer>
    </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    // components : {
    //   VExpansionPanelFooter,
    // },
    props: {
      name: String,
      title: String,
      pic: String,
      desc: String,
      height: Number,
      action: { 
        type: Object,
        default: null
      },
    },
    methods: {
      handleAction (action) {
        console.log('handling actions', action)
        if (action.type == 'href'){
          return window.open(action.content,'_blank');
        }
        return true
      },
    },
  }
</script>

<style>

</style>
