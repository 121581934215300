<template>
  <base-section
    id="affiliates"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading title="Conseil d'administration" />
      <v-row>
        <v-col
          v-for="(c, i) in ca"
          :key="i"
          cols="12"
          sm="4"
          md="3"
          lg="3"
          style="max-width: 275px;"
        >
          <people
            :name="c.name"
            :desc="c.desc"
            :title="c.title"
            :pic="c.pic ? c.pic : '/assets/team-0.svg'"
            :height="c.height"
            :action="c.action ? c.action : null"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import People from '@/components/People'
  export default {
    name: 'SectionConseil',
    components: {
      People,
    },
    data: () => ({
      ca: [
        {
          name: 'Alain FRACHON',
          title: 'Président',
          desc: 'Journaliste depuis 1974 – d’abord à la station de radio Europe Numéro 1, j’ai accompli l’essentiel de ma carrière à l’AFP puis au quotidien Le Monde. Pour l’AFP, j’ai travaillé à Téhéran, puis à Londres, puis à Washington, l’ensemble sur une période de dix ans. Pour Le Monde, que je rejoins en 1985, j’ai été journaliste au « desk » Moyen-Orient, correspondant à Jérusalem (1987-1991), correspondant à Washington, chef du service International (1994-1999), éditorialiste, responsable du magazine Le Monde2, directeur de la rédaction (2008-2011) et, depuis, chroniqueur de politique étrangère.',
          pic: '/assets/team-1.jpg',
          height: 725,
          action: { label: 'Déclaration d\'intérêt', type: 'href', content: '/assets/declarations/alain.pdf' },
        },
        {
          name: 'Nicole NOTAT',
          title: 'Administrateur',
          desc: 'Nicole Notat a démarré sa carrière professionnelle comme institutrice spécialisée. En 1982 elle devient secrétaire nationale de la CFDT, puis secrétaire générale de 1992 à 2002. Elle crée en 2003 Vigeo, une agence de notation sociale des entreprises qu’elle préside jusqu’en 2019. Elle est actuellement Présidente de l’Association Coup de Pouce qui agit pour la prévention du décrochage scolaire précoce. Elle préside le comité de mission de la MAIF. Elle est administratrice de l’entreprise à mission MON CDI Elle est coordinatrice de l’observatoire de la Raison d’être de l’institut du capitalisme responsable. Nicole Notat a animé avec Jean Dominique Senard la mission sollicitée par le gouvernement sur « l’entreprise objet d’intérêt collectif ». Elle a animé « le Segur de la santé » en 2020.',
          pic: '/assets/team-2.jpg',
          height: 725,
          action: { label: 'Déclaration d\'intérêt', type: 'href', content: '/assets/declarations/nicole.pdf' },
        },
        {
          name: 'Anne Le LORIER',
          title: 'Administrateur',
          desc: 'Anne Le Lorier a effectué la majeure partie de sa carrière à la Direction du Trésor, au Ministère des Finances et plus ponctuellement dans des cabinets ministériels.Elle a ensuite exercé des responsabilités chez EDF (Corporate Finance et Trésorerie ; Audi et risques) puis a été sous-gouverneur de la Banque de France.',
          // pic: '/assets/team-3.jpg',
          pic: '/assets/anne.png',
          height: 725,
          action: { label: 'Déclaration d\'intérêt', type: 'href', content: '/assets/declarations/anne.pdf' },
        },
        {
          name: 'Olivier MILOT',
          title: 'Administrateur',
          desc: 'A Télérama depuis 1999. Actuellement grand reporter au service « Art et Scènes », après différents postes occupés dans les services « Ecrans », « Questions d’actualités » « Reportages » et à « Sortir ». Début à La Correspondance de la presse puis à Stratégies, suivi de collaborations à plusieurs journaux, essentiellement dans la presse économique. Président de l’Association personnel de Télérama. Membre du conseil de surveillance de Télérama. Membre du conseil d’administration du « Pôle d’Indépendance » du Groupe Le Monde. ',
          pic: '/assets/team-4.jpg',
          height: 600,
          action: { label: 'Déclaration d\'intérêt', type: 'href', content: '/assets/declarations/olivier.pdf' },
        },
        {
          name: 'Jules NIEL',
          title: 'Administrateur',
          desc: 'Jules Niel, de nationalité française, est chargé d’affaires chez NJJ Telecom, la société holding des opérateurs de télécommunications Eir en Irlande, Salt en Suisse et Monaco Telecom. M. Niel est un ancien élève de l\'ESSEC Business School, où il a obtenu un diplôme de Master in Management - Grande École.',
          // pic: '/assets/team-5.jpg',
          height: 600,
          action: { label: 'Déclaration d\'intérêt', type: 'href', content: '/assets/declarations/jules.pdf' },
        },
        {
          name: 'Roxanne VARZA',
          title: 'Administrateur',
          desc: 'Roxanne est actuellement Directrice de STATION F, le plus grand campus de startups au monde, basé à Paris. En parallèle, elle est membre du conseil d\'administration de NRJ Group, scout pour le fonds Sequoia Capital, et business angel. Avant STATION F, Roxanne avait notamment travaillé pour TechCrunch et Microsoft. Elle est diplômée de UCLA, Sciences Po Paris et la London School of Economics.',
          pic: '/assets/roxanne.png',
          height: 600,
          action: { label: 'Déclaration d\'intérêt', type: 'href', content: '/assets/declarations/roxanne.pdf' },
        },
        {
          name: 'Sylvain COURAGE',
          title: 'Censeur',
          desc: 'Journaliste, Sylvain Courage a débuté à France Culture et collaboré au magazine Capital avant de rejoindre Le Nouvel Observateur en novembre 2000. Ex-chef des services «Dossier », « Notre époque » et « Économie » et ancien président de la Société des rédacteurs du Nouvel Observateur (2006-2012), il est aujourd’hui directeur adjoint de la rédaction du Nouvel Obs.',
          pic: '/assets/sylvain.png',
          height: 550,
          action: { label: 'Déclaration d\'intérêt', type: 'href', content: '/assets/declarations/sylvain.pdf' },
        },
      ],
    }),
  }
</script>
